<template>
  <div style="height:100vh">
    <!-- <div v-if="loader" class="relativeclass">
      <div class="spinnerstyle">
        <Spinner />
      </div>
    </div> -->
    <div class="login-main-container">
      <div class="h-100 d-flex align-items-center justify-content-center">
        <div class="login-main-sub-container position-relative p-0">
          <b-row class="m-0 w-100">
            <b-col class="p-0">
              <div
                class="w-100 pt-3 d-flex justify-content-center align-items-baseline"
              >
                <img src="../assets/newimgs/GREYLOGO.svg" alt="" />
              </div>
            </b-col>
          </b-row>
          <b-row class="m-0 w-100 mt-3 " style="height: 90%;">
            <b-col class="p-0 h-100">
              <div
                style="height: calc(100vh - 185px);"
                class=" d-flex align-items-center flex-column text-center testdetails-container"
              >
                <div
                  class="w-100 d-flex justify-content-center flex-column h-100"
                >
                  <h1 class="d-flex login-rightcont-headtxt">
                    System Permission
                  </h1>
                  <div class="mt-1" style="height: 94%;padding-right: 10px;">
                    <h4 class="d-flex typingtest-completed-txt">
                      Please provide the following system permission and Allow
                      access
                    </h4>
                    <div class="w-100 d-flex justify-content-center mt-4">
                      <div class="sc-alert-box p-4 " v-if="isDisabled() && !loader">
                        The system compatibility check failed. Refresh this page
                        after fixing the issues reported below.
                      </div>
                    </div>
                    <div
                      class="d-flex h-85 px85-py-0"
                      :class="isDisabled() ? 'mt-4' : 'mt-2'"
                    >
                 
                      <div 
                        class="h-100 d-flex align-items-center justify-content-center"
                        style="width: 40%; border-radius: 14px;"
                      >                                     
                        <div class="sc-black ml-3 w-100 h-100">
                          <div  v-if="loader"
        class="position-absolute"
        style="width: 30%;
    height: 61%; border-radius: 14px; z-index:100">
                          <b-skeleton class="w-100 h-100"
       
      />  </div>
                          <video
                            style="object-fit: unset; border-radius: 14px;transform: scaleX(-1);"
                            ref="camera"
                            class="w-100 h-100"
                            id="proctor"
                            autoplay
                            muted
                          ></video>
                         
                          <canvas  id="proctoring" class="w-100 h-100" hidden>
                          </canvas>
                          <audio autoplay ref="audio"></audio>
                        </div>
                      </div>
                      <div
                        class="sc-permissions-div ml-5"
                        style="width: 60%;height: 100%;overflow: auto;"
                      >
                        <div
                          class=" d-flex justify-content-between align-items-center w-100"
                        >
                          <div v-if="!loader" class="d-flex align-items-center w-25">
                            <img
                              src="../assets/newimgs/syscheckup/video.svg"
                              alt=""
                            />
                            <span class="ml-1 sysperm-icon-text">Camera</span>
                          </div>
                          <div v-else class="d-flex align-items-center w-25"><b-skeleton class="w-100 h-100"></b-skeleton></div>
                          <div
                            @click="camera = !camera"
                            v-closable="{
                              exclude: ['dropdown1'],
                              handler: 'hideCamera',
                            }"
                            class="position-relative d-flex flex-column align-items-start w-72 ml-3 pointer"
                            ref="dropdown1"
                          >
                            <div v-if="!loader"
                              class="ct-dropdown d-flex align-items-center justify-content-between position-relative w-100"
                            >
                              <div
                                class="text-left ct-dropdown-head"
                                style="width:95%;font-weight: 400"
                              >
                                <div class="text-truncate w-100">
                                  {{ selectedCamera }}
                                </div>
                              </div>
                              <div
                                style="width: 5%;"
                                class="d-flex align-items-center justify-content-end"
                              >
                                <img
                                  src="../assets/newimgs/syscheckup/downarrow.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                              <div v-else class="d-flex align-items-center justify-content-between position-relative w-100">
                                <b-skeleton class="w-100 h-100"></b-skeleton>
                              </div>
                            <span
                              class="sc-alert-txt"
                              v-if="VideoDevice.length === 0 && !loader"
                              >Camera is not available</span
                            >
                            <div
                              v-if="camera"
                              class="sc-dropdown w-100"
                              @click.stop="handleClick"
                            >
                              <b-form-group class="w-100">
                                <b-form-radio-group
                                  class="sc-add-drop w-100"
                                  id="cameras-list"
                                  value-field="text"
                                  text-field="text"
                                  v-model="selectedCamera"
                                  :options="VideoDevice"
                                  name="radio-btn-stacked"
                                  buttons
                                  stacked
                                  @change="handleSuccess()"
                                ></b-form-radio-group>
                              </b-form-group>
                            </div>
                          </div>
                        </div>

                        <div
                          class=" d-flex justify-content-between align-items-center w-100 mt-36px"
                        >
                          <div v-if="!loader" class="d-flex align-items-center w-25">
                            <img
                              src="../assets/newimgs/syscheckup/microphone.svg"
                              alt=""
                            />
                            <span class="ml-1 sysperm-icon-text"
                              >Microphone</span
                            >
                          </div>
                          <div v-else class="d-flex align-items-center w-25"><b-skeleton class="w-100 h-100"></b-skeleton></div>

                          <div
                            class="position-relative d-flex d-flex flex-column align-items-start w-72 ml-3 pointer"
                            @click="mic = !mic"
                            v-closable="{
                              exclude: ['dropdown2'],
                              handler: 'hideMic',
                            }"
                            ref="dropdown2"
                          >
                            <div v-if="!loader"
                              class="ct-dropdown d-flex align-items-center justify-content-between position-relative w-100"
                            >
                              <div
                                class="text-left ct-dropdown-head"
                                style="width:95%;font-weight: 400"
                              >
                                <div class="text-truncate w-100">
                                  {{ selectedMic }}
                                </div>
                              </div>
                              <div
                                style="width:5%"
                                class="d-flex align-items-center justify-content-end"
                              >
                                <img
                                  src="../assets/newimgs/syscheckup/downarrow.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div v-else class="d-flex align-items-center justify-content-between position-relative w-100">
                                <b-skeleton class="w-100 h-100"></b-skeleton>
                              </div>
                            <span
                              class="sc-alert-txt"
                              v-if="AudioDevice.length === 0 && !loader "
                              >Microphone is not available</span
                            >
                            <div
                              v-if="mic"
                              class="sc-dropdown w-100"
                              @click.stop="handleClick"
                            >
                              <b-form-group class="w-100">
                                <b-form-radio-group
                                  class="sc-add-drop w-100"
                                  id="mic-list"
                                  value-field="text"
                                  text-field="text"
                                  v-model="selectedMic"
                                  :options="AudioDevice"
                                  name="radio-btn-stacked"
                                  buttons
                                  stacked
                                  @change="handleSuccessaudio()"
                                ></b-form-radio-group>
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex justify-content-between align-items-center w-100 mt-36px"
                        >
                          <div v-if="!loader" class="d-flex align-items-center w-25">
                            <img
                              src="../assets/newimgs/syscheckup/speaker.svg"
                              alt=""
                            />
                            <span class="ml-1 sysperm-icon-text">Speaker</span>
                          </div>
                          <div v-else class="d-flex align-items-center w-25"><b-skeleton class="w-100 h-100"></b-skeleton></div>

                          <div
                            class="position-relative d-flex flex-column align-items-start w-72 ml-3 pointer"
                            @click="speaker = !speaker"
                            v-closable="{
                              exclude: ['dropdown3'],
                              handler: 'hideSpeaker',
                            }"
                            ref="dropdown3"
                          >
                            <div v-if="!loader"
                              class="ct-dropdown d-flex align-items-center justify-content-between position-relative w-100"
                            >
                              <div
                                class="text-left ct-dropdown-head"
                                style="width: 95%;font-weight: 400;"
                              >
                                <div class="w-100 text-truncate">
                                  {{ selectedSpeaker }}
                                </div>
                              </div>
                              <div
                                style="width: 5%;"
                                class="d-flex align-items-center justify-content-end"
                              >
                                <img
                                  src="../assets/newimgs/syscheckup/downarrow.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div v-else class="d-flex align-items-center justify-content-between position-relative w-100">
                                <b-skeleton class="w-100 h-100"></b-skeleton>
                              </div>
                            <span
                              class="sc-alert-txt"
                              v-if="OutputDevice.length === 0 && !loader"
                              >Speaker is not available</span
                            >
                            <div
                              v-if="speaker"
                              class="sc-dropdown w-100"
                              @click.stop="handleClick"
                            >
                              <b-form-group class="w-100">
                                <b-form-radio-group
                                  class="sc-add-drop w-100"
                                  id="speaker-list"
                                  value-field="text"
                                  text-field="text"
                                  v-model="selectedSpeaker"
                                  :options="OutputDevice"
                                  name="radio-btn-stacked"
                                  buttons
                                  stacked
                                  @change="speakeroutput()"
                                ></b-form-radio-group>
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                        <div class="mt-4">
                          <div v-if="!loader" class="fdbck-question-txt fw600 text-left">
                            Check your Microphone and Speaker
                          </div>
                          <div v-else class="fdbck-question-txt fw600 text-left">
                         <b-skeleton class="w-50 h-100"></b-skeleton>
</div>
                          <div v-if="!loader"
                            class="mt-3 d-flex justify-content-center flex-column align-items-center w-100"
                          >
                            <div v-if="toggle === true" v-show="false">
                              <img src="../assets/newimgs/waves.svg" alt="" />
                            </div>
                            <div v-else v-show="true">
                              <img src="../assets/newimgs/waves.svg" alt="" />
                            </div>
                            <div v-show="toggle">
                              <canvas
                                ref="waveformCanvas"
                                width="450"
                                height="30"
                              ></canvas>
                              <div
                                v-if="
                                  OutputDevice.length !== 0 &&
                                    AudioDevice.length !== 0
                                "
                                class="fdbck-question-txt text-center"
                                style="margin-top: -14px;"
                              >
                                Please Speak To Test Mic and Speaker
                              </div>
                            </div>
                            <div class="bluebtns ml-3 mt-4">
                              <div v-if="!disabled" v-show="true">
                                <b-button
                                  variant="primary"
                                  class="pl-4 pr-4"
                                  id="click"
                                  @click="
                                    startAudioCheck();
                                    toggle = true;
                                  "
                                  >Start Testing</b-button
                                >
                              </div>
                              <div v-else v-show="true">
                                <b-button
                                  variant="primary"
                                  class="pl-4 pr-4"
                                  @click="
                                    stoppaudioStream();
                                    toggle = false;
                                  "
                                  >Stop Testing</b-button
                                >
                              </div>
                            </div>
                          </div>
                          <div v-else
                            class="mt-3 d-flex justify-content-center flex-column align-items-center w-100"
                          >
                            <b-skeleton class="w-100 h-100"></b-skeleton>
                         
                            <div class=" ml-3 mt-4 w-100 justify-content-center d-flex">
                             <b-skeleton class="w-25 h-100"></b-skeleton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="mb-0" />
              <div
                class="p-3 d-flex align-items-center justify-content-end bluebtns"
              >
                <b-button
                  variant="primary"
                  class="pl-4 pr-4"
                  @click="goToNext()"
                  :disabled="isDisabled()"
                  >Continue</b-button
                >
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <b-modal
        id="systemcheckmodal"
        size="lg"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
        :no-close-on-esc="true"
      >
        <div
          class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
        >
          Alert !
        </div>
        <div
          @click="$bvModal.hide('systemcheckmodal')"
          class="modalcloseicon pointer"
        >
          <img src="../assets/newimgs/closeicon.svg" alt="" />
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <img src="../assets/newimgs/alerticon.svg" alt="" />
          </div>
          <div class="newmodal-body-content-ques mt-4">
            It seems you have made changes in your <b>"System Permissions"</b>
          </div>
          <div class="newmodal-body-content mt-4">
            Kindly reload the page and give the required permissions.
          </div>
          <div class="mt-2">
            <img src="../assets/images/step3.svg" alt="" />
          </div>
          <div class="bluebtns mt-5">
            <b-button
              class="mr-3 pl-4 pr-4"
              variant="outline-primary"
              @click="$bvModal.hide('systemcheckmodal')"
              >Okay</b-button
            >
          </div>
        </div>
      </b-modal>
      <b-modal
        id="validatewindowsmodel"
        size="lg"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
        :no-close-on-esc="true"
      >
        <div
          class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
        >
          Sorry for the Inconvenience !
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <img src="../assets/newimgs/alerticon.svg" alt="" />
          </div>
          <div class="newmodal-body-content-ques mt-4">
            It seems you are not having <b>"Windows 10 or above Version"</b>
          </div>
          <div class="newmodal-body-content mt-4">
            You need to come up with windows 10 or above to proceed with the
            test.
          </div>
        </div>
      </b-modal>
      <b-modal
        id="validateCPUmodel"
        size="lg"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
        :no-close-on-esc="true"
      >
        <div
          class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
        >
          Sorry for the Inconvenience !
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <img src="../assets/newimgs/alerticon.svg" alt="" />
          </div>
          <div class="newmodal-body-content-ques mt-4">
            It seems you are having <b>"32 bits Operating System"</b>
          </div>
          <div class="newmodal-body-content mt-4">
            You need to come up with 64 bits OS to proceed with the test.
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import SystemCheck from "../mixins/systemcheck";

export default {
  mixins: [SystemCheck],
};
</script>
<style>
.sc-alert-box {
  background-color: #ffffff;
  border-radius: 8px;
  width: fit-content;
  color: #ff4752;
  font-size: 15px;
  font-weight: 500;
}

.sc-alert-txt {
  font-size: 14px;
  color: #ff4752;
  font-weight: 400;
}
</style>
