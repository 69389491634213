<template>
  <div>
    <div class="mobileScreen-main-container">
      <MobileHeader />

      <div class="mobileScreen-main-innerContainer">
        <div class="m-3">
          <div class="mobileScreen-TD-head">
            System Permission
          </div>
          <div class="mt-1 mobile-login-sub-font ">
            Please provide the following system permission and allow access.
          </div>
        </div>
        <hr />
        <div class="mobileScreen-systemCheck-mainBody ml-3">
          <div class="mt-2">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <img src="../../assets/mobile-newUI-imgs/camera.svg" alt="" />

                <div class="mobileScreen-TD-body-subhead ml-2">Camera</div>
              </div>
              <div class="mobileScreen-SC-switch">
                <b-form-checkbox
                  v-model="cameraActive"
                  switch
                  size="lg"
                  @change="toggleCamera()"
                ></b-form-checkbox>
              </div>
            </div>
            <div class="mt-2 w65">
              <img
                class="w-100"
                src="../../assets/mobile-newUI-imgs/camera-img.svg"
                alt=""
              />
            </div>
          </div>

          <div class="mt-4">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <img
                  src="../../assets/mobile-newUI-imgs/microphone.svg"
                  alt=""
                />
                <div class="mobileScreen-TD-body-subhead ml-2">
                  Microphone
                </div>
              </div>
              <div class="mobileScreen-SC-switch">
                <b-form-checkbox
                  v-model="micActive"
                  switch
                  size="lg"
                  @change="toggleMic()"
                ></b-form-checkbox>
              </div>
            </div>
            <div class="mt-2 w65">
              <img
                class="w-100"
                src="../../assets/mobile-newUI-imgs/microphone-img.svg"
                alt=""
              />
            </div>
          </div>

          <div class="mt-4 mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <img src="../../assets/mobile-newUI-imgs/location.svg" alt="" />

                <div class="mobileScreen-TD-body-subhead ml-2">Location</div>
              </div>
              <div class="mobileScreen-SC-switch">
                <b-form-checkbox
                  v-model="locationActive"
                  switch
                  size="lg"
                  @change="toggleLocation()"
                ></b-form-checkbox>
              </div>
            </div>
            <div class="mt-2 w65">
              <img
                class="w-100"
                src="../../assets/mobile-newUI-imgs/location-img.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div
          class="mobileScreen-TD-footer d-flex align-items-center justify-content-between bluebtns"
        >
          <!-- <b-button class="mobileScreen-leaveBtn w-100 mr-3"
          >Leave</b-button
        > -->
          <b-button class="mobileScreen-startBtn w-100" @click="goToNext()"
            >Continue</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MobileHeader from "./MobileHeader.vue";
import { mapGetters } from "vuex";

export default {
  name: "MsystemCheck",
  components: {
    MobileHeader,
  },
  data() {
    return {
      cameraActive: false,
      micActive: false,
      locationpermission: false,
      locationActive: false,
    };
  },
  created() {
    this.askCameraPermission();
    this.askMicPermission();
    this.askLocationPermission();
  },
  computed: {
    ...mapGetters("sectionVUEX", {
      testDetails: "getTestDetails",
      uuid: "getUUID",
      licenseKey: "getlicensekey",
      statsLocation: "getStatsLocation",
      userDetials: "getUserDetails",
    }),
  },
  methods: {
    askLocationPermission() {
      if (!("geolocation" in navigator)) {
        this.errorStr = "Geolocation is not available.";
        return;
      }
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.locationpermission = true;
          this.locationActive = true;
          this.location = pos;
        },
        (err) => {
          console.log(err);
          this.locationpermission = false;
          this.locationActive = false;
          this.errorStr = err.message;
        }
      );
    },

    candidateteststatsPost() {
      let geolocation = {};
      try {
        geolocation.latitude = JSON.stringify(this.location.coords.latitude);
        geolocation.longitude = JSON.stringify(this.location.coords.longitude);
        this.$store.commit("sectionVUEX/savestatsLoaction", geolocation);
        this.locationpermission = true;
        this.locationActive = true;
      } catch (error) {
        this.locationpermission = false;
        this.locationActive = false;
      }
    },
    async askCameraPermission() {
      try {
        await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        this.cameraActive = true;
      } catch (err) {
        this.cameraActive = false;
        return err;
      }
    },
    async askMicPermission() {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.micActive = true;
      } catch (err) {
        this.micActive = false;
        return err;
      }
    },

    toggleLocation() {
      if (!this.locationActive) {
        this.askLocationPermission();
        this.locationActive = true;
      }
    },

    toggleCamera() {
      if (!this.cameraActive) {
        this.askCameraPermission();
        this.cameraActive = true;
      }
    },

    toggleMic() {
      if (!this.micActive) {
        this.askMicPermission();
        this.micActive = true;
      }
    },

    async goToNext() {
      this.candidateteststatsPost();
      const isCameraGranted = await this.checkPermissionGranted("camera");
      const isMicroGranted = await this.checkPermissionGranted("microphone");
      const isLocationGranted = this.locationpermission;
      if (!isCameraGranted || !isMicroGranted || !isLocationGranted) {
        alert("Please enable all permissions");
      } else {
        this.havePermissions = true;
        this.$router.push({
          name: "StudentLogin",
          query: {
            uuid: this.$route.query.uuid,
          },
        });
      }
    },

    async checkPermissionGranted(key) {
      try {
        const permission = await navigator.permissions.query({ name: key });
        return permission.state === "granted";
      } catch (error) {
        return false;
      }
    },
  },
};
</script>
