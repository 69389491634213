import { mapGetters } from "vuex";
import Spinner from "../components/fixedcomponents/Spinner.vue";
import drawWaveForm from "../mixins/drawWaveForm.js";
import {onSave}  from "./LoginFromCand.js";
import LoginModals from "../components/modals/LoginToastModals"

const systemCheck = {
  name: "SystemCheck",
  mixins: [drawWaveForm],
  components: {
    Spinner,
    LoginModals
  },
  data() {
    return {
      loader: false,
      cameraButton: false,
      audioButton: false,
      camera_selected: null,
      audio_selected: null,
      audiostream: null,
      stream: undefined,
      max: 100,
      audiodeviceselection: {},
      deviceSelection: {},
      VideoId: "",
      VideoDevice: [],
      selectedCamera: "",
      AudioId: "",
      AudioDevice: [],
      selectedMic: "",
      SpeakerId: "",
      OutputDevice: [],
      selectedSpeaker: "",
      camera: false,
      mic: false,
      speaker: false,
      disabled: false,
      audioContext: null,
      speakerSource: null,
      toggle: null,
    };
  },

  created() {
    localStorage.clear();
    this.loader = true;
    this.validatingWindows10OrAbove();
    this.validatingCPU();
    this.askCameraPermission();
    this.startStream();
    this.startAudioStream();
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        return pos
      },
      (err) => {
        return err
      }
    );
  },

  computed: {
    ...mapGetters("sectionVUEX", {
      isLoadingStatus: "getIsLoadingStatus",
      testDetails: "getTestDetails",
      userID: "getUserID",
      uuid: "getUUID",
    }),
  },
  methods: {
    async validatingCPU() {
      try {
        await navigator.userAgentData
          .getHighEntropyValues(["architecture", "bitness"])
          .then((ua) => {
            if (navigator.userAgentData.platform === "Windows") {
              this.validatingArchitecture(ua);
            } else {
              console.log("Not running on Windows");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },

    validatingArchitecture(ua) {
      if (ua.architecture === "x86" || ua.architecture === "arm") {
        if (ua.bitness === "32") {
          this.$bvModal.show("validateCPUmodel");
        }
      }
    },

    async validatingWindows10OrAbove() {
      try {
        await navigator.userAgentData
          .getHighEntropyValues(["platformVersion"])
          .then((ua) => {
            if (navigator.userAgentData.platform === "Windows") {
              const majorPlatformVersion = parseInt(
                ua.platformVersion.split(".")[0]
              );
              if (majorPlatformVersion >= 13 || majorPlatformVersion > 0) {
                console.log("Windows 10 or above detected");
              } else {
                this.$bvModal.show("validatewindowsmodel");
              }
            } else {
              console.log("Not running on Windows");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },

    isDisabled() {
      return (
        this.VideoDevice.length === 0 ||
        this.AudioDevice.length === 0 ||
        this.OutputDevice.length === 0
      );
    },

    hideCamera() {
      this.camera = false;
    },
    hideMic() {
      this.mic = false;
    },
    hideSpeaker() {
      this.speaker = false;
    },
    handleClick(event) {
      console.log("close")
      // Check if the clicked option is already selected
      const clickedOption = event.target.innerText;
      if (clickedOption === this.selectedSpeaker) {
        this.speaker = false; // Close the dropdown if clicking on the selected option
      }
      else if(clickedOption===this.selectedMic){
        this.mic=false;
      }
      else if(clickedOption===this.selectedCamera){
        this.camera=false;
      }
    },
    async startAudioStream() {
      this.audioButton = !this.audioButton; // Toggle the cameraButton property to indicate whether the camera stream is active or not.
      try {
        // Try to access the user's media devices (e.g., webcam) to get a video stream.
        // The 'this.deviceSelection' likely represents the selected video device.
        // Note: 'getUserMedia' is an asynchronous function, so we use 'await' with 'async' keyword here.
        await navigator.mediaDevices
          .getUserMedia({
            audio: this.deviceSelection,
          })
          .then(() => {
            this.getAudioDevices();
          });
      } catch (e) {
        console.log("Error" + e);
      }
    },

    // This function is responsible for starting the video stream from the user's media devices.
    startStream: async function() {
      this.cameraButton = !this.cameraButton; // Toggle the cameraButton property to indicate whether the camera stream is active or not.
      try {
        // Try to access the user's media devices (e.g., webcam) to get a video stream.
        // The 'this.deviceSelection' likely represents the selected video device.
        // Note: 'getUserMedia' is an asynchronous function, so we use 'await' with 'async' keyword here.
        await navigator.mediaDevices
          .getUserMedia({
            video: this.deviceSelection,
          })
          .then((stream) => {
            this.$refs.camera.srcObject = stream; // If successful, set the obtained stream as the source object for the 'this.$refs.camera' element.
            window.localVideoStream = stream; // Store the stream object in a global variable for later access (if needed).
            this.getCameraDevice();
          });
      } catch (e) {
        return e
      }
    },

    // This function is responsible for asking the permission for accessing audio and video.
    async askCameraPermission() {
      try {
        await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
      } catch (err) {
        this.loader = false;
      }
    },

    getCameraDevice() {
      this.VideoDevice = [];
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        devices.forEach((device) => {
          //This condition fetches all the video devices attached to the system
          if (device.kind === "videoinput") {
            this.VideoDevice.push({
              value: device.deviceId,
              text: device.label,
            });
            this.VideoId = this.VideoDevice[0].value;
            this.selectedCamera = this.VideoDevice[0].text;
          }
        });
        this.loader = false;
      });
    },

    getAudioDevices() {
      this.AudioDevice = [];
      this.OutputDevice = [];
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        devices.forEach((device) => {
          //This condition fetches all the audio devices attached to the system
          if (device.kind === "audioinput") {
            this.AudioDevice.push({
              value: device.deviceId,
              text: device.label,
            });
            this.AudioId = this.AudioDevice[0].value;
            this.selectedMic = this.AudioDevice[0].text;
          }
          //This condition fetches all the speakers attached to the system
          if (device.kind === "audiooutput") {
            this.OutputDevice.push({
              value: device.deviceId,
              text: device.label,
            });
            this.SpeakerId = this.OutputDevice[0].value;
            this.selectedSpeaker = this.OutputDevice[0].text;
          }
        });
        this.loader = false;
      });
    },

    // Function to handle the success case when obtaining access to the user's media devices (e.g., webcam).
    handleSuccess() {
      const video = document.querySelector("video"); // Get a reference to the video element on the webpage.
      let camerasList = document.querySelector("#cameras-list"); // Get a reference to the cameras-list element on the webpage.
      let selected = camerasList.value; // Get the selected value (camera device ID) from the cameras-list dropdown.

      // Set the deviceSelection property based on the selected value from the dropdown.
      // If a specific camera is selected (selected has a value), use the deviceId in the deviceSelection.
      // Otherwise, use true in the deviceSelection, which may indicate using the default camera.
      this.deviceSelection = selected ? { deviceId: selected } : true;

      navigator.mediaDevices.getUserMedia({
        // Attempt to get access to the user's media devices (e.g., webcam) using getUserMedia.
        video: this.deviceSelection,
      });

      // After obtaining access to the media devices, try to get the video stream again with getUserMedia.
      // This time, we use a 'then' callback to handle the success case.
      navigator.mediaDevices
        .getUserMedia({
          video: this.deviceSelection,
        })
        .then(function(stream) {
          // If the getUserMedia call is successful, the 'stream' object represents the video stream.
          // Set the 'srcObject' property of the 'video' element to display the video feed.
          video.srcObject = stream;
        });
      this.camera = false;
    },

    // Function to handle the success case when obtaining access to the user's audio input devices (e.g., microphones).
    handleSuccessaudio() {
      let micselected = this.selectedMic; // Get the selected value (microphone device ID) from the mic-list dropdown.

      // Set the audiodeviceselection property based on the selected value from the dropdown.
      // If a specific microphone is selected (micselected has a value),
      // use the deviceId in the audiodeviceselection.
      // Otherwise, use true in the audiodeviceselection, which may indicate using the default microphone.
      this.audiodeviceselection = micselected
        ? { deviceId: micselected }
        : true;

      // Attempt to get access to the user's audio input devices (e.g., microphones) using getUserMedia.
      // Note: This call doesn't handle the audio stream; it just tries to get access.
      navigator.mediaDevices.getUserMedia({
        audio: this.audiodeviceselection,
      });
      this.mic = false;
    },

    // Function to handle the selection of a speaker output device for audio playback.
    speakeroutput() {
      let speakerselected = this.selectedSpeaker; // Get the selected value (speaker output device ID) from the speaker-list dropdown.

      // Set the audioutputdevice property based on the selected value from the dropdown.
      // If a specific speaker output device is selected (speakerselected has a value),
      // use the deviceId in the audioutputdevice.
      // Otherwise, use true in the audioutputdevice, which may indicate using the default speaker.
      this.audioutputdevice = speakerselected
        ? { deviceId: speakerselected }
        : true;
      this.speaker = false;
    },

    // This function starts the process of obtaining user's audio input (microphone).
    startAudioCheck: async function() {
      try {
        //this.recordedUrl = null;
        // Request access to the user's audio input using getUserMedia.
        // The "audio" constraint is set based on the "audiodeviceselection" parameter.
        this.audiostream = await navigator.mediaDevices.getUserMedia({
          audio: this.audiodeviceselection,
        });
        // If audio access is granted, proceed with the following actions:
        // Handle the logic for a successful audio stream acquisition.
        this.handleSuccessaudio();

        this.speakeroutput(); // Handle the configuration of the audio output, if required.
        this.Checkaudio(); // Check the properties or capabilities of the obtained audio stream.
      } catch (e) {
        return e;
      }
    },

    Checkaudio() {
      try {
        // Initialize the audio context
        this.audioContext = new window.AudioContext();

        // Get microphone input
        const microphoneInput = this.audioContext.createMediaStreamSource(
          this.audiostream
        );

        const audioOutput = this.audioContext.destination;
        microphoneInput.connect(audioOutput);
        this.canvasContext = this.$refs.waveformCanvas.getContext("2d");
        this.analyserNode = this.audioContext.createAnalyser();
        microphoneInput.connect(this.analyserNode);
        this.drawWaveform();
        this.disabled = true;
      } catch (error) {
        console.error("Error starting test:", error);
      }
    },

    stoppaudioStream() {
      clearInterval(this.drawInterval);
      if (this.audioContext) {
        this.audioContext.close();
      }
      if (this.audiostream) {
        this.audiostream.getTracks().forEach((track) => track.stop());
      }
      this.disabled = false;
    },

    async goToNext() {
      this.stoppaudioStream();
      const isCameraGranted = await this.checkPermissionGranted("camera");
      const isMicroGranted = await this.checkPermissionGranted("microphone");
      if (!isCameraGranted || !isMicroGranted) {
        this.isDisabled();
        this.$bvModal.show("systemcheckmodal");
      } else {

        // uncomment below when query comes
  if(this.checkAccessTokenCookie()&&this.$route.query.from=='candidate'){
    localStorage.setItem("fromCandidate", true);

    console.log(this.$route.query.uuid,"this.$route.query.uuid")
onSave(this.checkAccessTokenCookie(),this.$route.query.uuid, this.$router);
  }
//   if(this.checkAccessTokenCookie()){
//     localStorage.setItem("fromCandidate", true);

//     console.log(this.$route.query.uuid,"this.$route.query.uuid")
// onSave(this.checkAccessTokenCookie(),this.$route.query.uuid, this.$router);
//   }
  else{
        this.$router.push({
          name: "StudentLogin",
          query: {
            uuid: this.$route.query.uuid,
          },
        });
      }
    }
    },
    checkAccessTokenCookie() {
      const cookies = document.cookie.split('; ');
      for (let i = 0; i < cookies.length; i++) {
        const cookiePair = cookies[i].split('=');
        if (cookiePair[0] === 'accessToken') {
          return cookiePair[1]
        }
      }
      return false;
    },
    async checkPermissionGranted(key) {
      try {
        const permission = await navigator.permissions.query({ name: key });
        return permission.state === "granted";
      } catch (error) {
        return false;
      }
    },
  },
};
export default systemCheck;
