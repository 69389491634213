<template>
  <div>
    <MobileSystemCheck v-if="!isLaptop" />
    <LaptopSystemCheck v-else />
  </div>
</template>

<script>
import MobileSystemCheck from "../components/mobileView/MsystemCheck.vue";
import LaptopSystemCheck from "../components/SystemCheck.vue";
export default {
  name: "SystemCheck",
  data() {
    return {
      isLaptop: true,
    };
  },
  components: {
    MobileSystemCheck,
    LaptopSystemCheck,
  },
  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize); // Add event listener for window resize
    console.log("mount");
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize); // Remove event listener before component unmounts
  },
  methods: {
    checkScreenSize() {
      this.isLaptop = window.innerWidth >= 1024;
    },
  },
};
</script>

